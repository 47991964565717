<template>
  <div class="row">
    <content-header :title="$t('plusClientCreation')" />
    <div class="col-12">
      <div class="card p-4" style="border: white">
        <validation-observer ref="plusClientFormRef" novalidate>
          <div class="row">
            <div class="col-lg-4 col-md-4 mt-2">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="value.organizationId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="text"
                  fieldvalue="value"
                  :label="$t('organization')"
                  :options="collections.organizations"
                />
              </validation-provider>
            </div>
            <div class="col-lg-4 col-md-4 mt-2">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="value.companyId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="text"
                  fieldvalue="value"
                  :label="$t('company')"
                  :options="collections.companies"
                />
              </validation-provider>
            </div>
            <div class="col-lg-4 col-md-4 mt-2">
              <validation-provider v-slot="{ errors }" rules="required|max:64">
                <base-input
                  v-model="value.name"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :label="$t('nameApptPlus')"
                />
              </validation-provider>
            </div>
          </div>
          <div class="row">
            <div class="col-12 float-right mt-3">
              <button
                v-if="hideSubmitButton === undefined"
                class="btn btn-primary float-right mt-4"
                @click="onSubmitPlusClient()"
              >
                <i class="far fa-save" /> {{ $t('save') }}
              </button>
            </div>
          </div>
        </validation-observer>
      </div>
    </div>
    <slot />

    <div v-if="editMode" class="col-12">
      <validation-observer ref="displayParametersFormRef" novalidate>
        <div class="row">
          <div class="col-12 my-4">
            <h4 class="font-weight-bolder float-left">
              {{ $t('parameters') }}
            </h4>
            <hr />
          </div>
          <div class="col-12">
            <div class="card p-4" style="border: white">
              <div class="row">
                <div class="col-lg-3 col-md-5">
                  <label>{{ $t('plusClientPreloadService') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.preloadService" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-5">
                  <label>{{ $t('plusClientAllowServiceChange') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.allowServiceChange" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-5">
                  <label>{{ $t('plusClientAllowSubService') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.allowSubService" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-5">
                  <label>{{ $t('plusClientAllowCitySelection') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.allowCitySelection" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-5">
                  <label>{{ $t('plusClientDisplayOnlyWithAvailableSpaces') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.displayOnlyWithAvailableSpaces" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-5">
                  <label>{{ $t('plusClientAllowDocumentUpload') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.allowDocumentUpload" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-5">
                  <label>{{ $t('plusClientDisablePasteInConfirmationEmail') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.disablePasteOnEmailConfirmation" />
                </div>                
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-5">
                  <label>{{ $t('plusClientShowAttendeesNumber') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.showAttendeesNumber" />
                </div>
              </div>    
              <div class="row">
                <div class="col-lg-3 col-md-5">
                  <label>{{ $t('useMessageNotFound') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.useCustomSearchMessage" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-5">
                  <validation-provider v-if="value.useCustomSearchMessage" v-slot="{ errors }" rules="required|max:128">
                    <base-input 
                      v-model="value.notFoundMessage_ES"
                      :label="$t('textSpanish')"
                      :max-length="128"
                      :error="errors[0]"
                      :error-msg="$t('requiredField')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-5">
                  <validation-provider v-if="value.useCustomSearchMessage" v-slot="{ errors }" rules="required|max:128">
                    <base-input 
                      v-model="value.notFoundMessage_EN"
                      :label="$t('textEnglish')"
                      :max-length="128"
                      :error="errors[0]"
                      :error-msg="$t('requiredField')"
                    />
                  </validation-provider>
                </div>
              </div>            
            </div>
          </div>
        </div>
      </validation-observer>
    </div>

    <div class="my-5 float-right col-12">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="OnCancel"
        :text="$t('cancel')"
      />
      <button
        v-if="editMode"
        class="btn btn-primary float-right ml-2"
        @click="onSaveParameters(true)"
      >
        {{ $t('saveContinue') }} <i class="far fa-arrow-alt-circle-right" />
      </button>
      <button
        v-if="editMode"
        class="btn btn-outline-primary float-right"
        variant="outline-primary"
        @click="onSaveParameters()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>

      <slot name="buttons" />
    </div>
  </div>
</template>
<script>
import { getOrganizations as _getOrganizations } from '@/services/OrganizationService';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import {
  submitPlusClient as _submitPlusClient,
  saveParameters as _saveParameters,
} from '@/services/PlusClientService';
import BaseInput from '@/components/BaseInput.vue';
import contentHeader from '@/components/Header';

export default {
  name: 'DisplayCreate',
  components: { BaseInput, contentHeader },
  data() {
    return {
      collections: {
        organizations: [],
        companies: [],
      },
      edit: false,
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    hideSubmitButton: {
      type: undefined,
      default: () => undefined,
    },
  },
  methods: {
    async loadInitialFilters() {
      await _getOrganizations()
        .then(async (resp) => {
          this.collections.organizations = resp.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getAvailableCompanies(organizationId) {
      await _getCompanies(organizationId)
        .then((response) => {
          this.collections.companies = this.collections.companies =
            response.data || [];
          if (
            this.collections.companies &&
            this.collections.companies.length == 1
          ) {
            this.value.companyId = this.collections.companies[0].value;
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmitPlusClient(next = false) {
      if (!(await this.$refs.plusClientFormRef.validate())) return;

      const payload = {
        plusClientId: this.value.plusClientId,
        organizationId: this.value.organizationId,
        companyId: this.value.companyId,
        name: this.value.name,
      };

      _submitPlusClient(payload)
        .then((response) => {
          this.ShowSuccessSaveToast();

          if (!next) {
            if (this.editMode) this.$emit('submitted');
            else
              this.$router.push({
                name: 'plusClientEdit',
                params: { id: response.data },
              });
            return;
          }

          if (next) this.$emit('continue', 1);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSaveParameters(next = false) {
      if (!(await this.$refs.displayParametersFormRef.validate())) return;
      const payload = {
        plusClientId: this.value.plusClientId,
        preloadService: this.value.preloadService,
        allowServiceChange: this.value.allowServiceChange,
        allowSubService: this.value.allowSubService,
        allowCitySelection: this.value.allowCitySelection,
        displayOnlyWithAvailableSpaces:
          this.value.displayOnlyWithAvailableSpaces,
        allowDocumentUpload: this.value.allowDocumentUpload,
        disablePasteOnEmailConfirmation: this.value.disablePasteOnEmailConfirmation,
        showAttendeesNumber: this.value.showAttendeesNumber,
        useCustomSearchMessage: this.value.useCustomSearchMessage,
        notFoundMessage_ES: this.value.useCustomSearchMessage ? this.value.notFoundMessage_ES : null,
        notFoundMessage_EN: this.value.useCustomSearchMessage ? this.value.notFoundMessage_EN : null,
      };

      _saveParameters(payload)
        .then(() => {
          this.ShowSuccessSaveToast();
          if (next) this.$emit('continue', 1);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async OnCancel() {
      this.$router.push({ name: 'plusClientsList' });
    },
  },
  computed: {
    editMode() {
      return this.$route.name === 'plusClientEdit';
    },
    cloneMode() {
      return this.$route.name === 'plusClientClone';
    },
  },
  mounted() {
    this.loadInitialFilters();
  },
  watch: {
    'value.organizationId': {
      immediate: true,
      handler(value) {
        this.value.companyId = null;
        this.collections.companies = [];
        if (value) this.getAvailableCompanies(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
h4.font-weight-bolder {
  background-color: $color-app-background;
  padding-right: 10px;
}
.title {
  font-size: 14px;
  color: $color-primary;
}
.macadddress-list {
  border: 1px solid $color-label-default !important;
  border-radius: 5px;
}
.btnStyle {
  font: normal normal normal 10px Lato;
  padding: 5px;
}
.custom-range {
  border-radius: 10px;
}
</style>
