<template>
  <div class="row">
    <div class="col-md-3">
      <filter-color
        v-model="model.footerBackgroundTopColor"
        :label="$t('footerBackgroundTopColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.footerFontTopColor"
        :label="$t('footerFontTopColor')"
      />
    </div>
    <div class="col-md-12 text-right pt-4">
      <button class="btn btn-danger mr-2" @click="onClean()">
        <i class="fas fa-eraser" /> {{ $t('clear') }}
      </button>
      <button
        class="btn btn-outline-primary"
        variant="outline-primary"
        @click="onSubmit()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>
import { editPlusClientLayout as _editPlusClientLayout } from '@/services/PlusClientService';

export default {
  name: 'Home',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      model: {
        footerBackgroundTopColor: null,
        footerBackgroundBottomColor: null,
        footerFontTopColor: null,
        footerFontBottomColor: null,
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.model = {
          footerBackgroundTopColor: val.footerBackgroundTopColor,
          footerBackgroundBottomColor: val.footerBackgroundBottomColor,
          footerFontTopColor: val.footerFontTopColor,
          footerFontBottomColor: val.footerFontBottomColor,
        };
      },
    },
  },
  methods: {
    async onSubmit() {
      const model = { ...this.value, ...this.model };
      _editPlusClientLayout(model)
        .then(() => {
          this.$emit('input', model);
          this.ShowSuccessSaveToast();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onClean() {
      const model = {
        footerBackgroundTopColor: null,
        footerBackgroundBottomColor: null,
        footerFontTopColor: null,
        footerFontBottomColor: null,
      };
      this.$emit('on-clean', model);
    },
  },
};
</script>
