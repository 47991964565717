<template>
  <div class="row">
    <div class="col-md-12">
      <confirmation-modal v-model="showCleanModal" text="doYouWantToRemoveAllStyles" @on-reject="showCleanModal = false" @on-confirm="onClean()" />
      <action-button :actions="actions" :text="$t('actions')" />
    </div>
    <div class="col-md-12 mt-4">
      <Panels :options="panelOptions">
        <template v-slot:HEADER_SECTION>
          <HeaderSection 
            :mobile="true"
            :value="value"
            @input="$emit('input', $event)"
            @on-clean="onSectionClean($event)" 
          />
        </template>
        <template v-slot:CONTENT_SECTION>
          <ContentSection :value="value" @on-clean="onSectionClean($event)" @input="$emit('input', $event)" />
        </template>
        <template v-slot:BUTTONS_SECTION>
          <ButtonsSection :value="value"  @on-clean="onSectionClean($event)" @input="$emit('input', $event)"/>
        </template>
        <template v-slot:CONTROLS_SECTION>
          <ControlsSection :value="value"  @on-clean="onSectionClean($event)" @input="$emit('input', $event)"/>
        </template>
        <template v-slot:CALENDAR_SECTION>
          <CalendarSection :value="value" @on-clean="onSectionClean($event)" @input="$emit('input', $event)"/>
        </template>
        <template v-slot:TABLES_SECTION>
          <TablesSection :value="value" @on-clean="onSectionClean($event)"  @input="$emit('input', $event)"/>
        </template>
        <template v-slot:FOOTER_SECTION>
          <FooterSection :value="value" @on-clean="onSectionClean($event)" @input="$emit('input', $event)"/>
        </template>
      </Panels>
    </div>
    <div class="col-md-12 mt-3">
      <button
        class="btn btn-default mr-2"
        @click="$emit('go-to', 1)"
      >
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </button>
      <button
        class="btn btn-outline-primary float-right"
        variant="outline-primary"
        @click="$router.push({name: 'plusClientsList'})"
      >
        {{ $t('finalize') }} <i class="far fa-arrow-alt-circle-right" />
      </button>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/basics/buttons/ActionButton';
import Panels from '@/components/Panels.vue';
import HeaderSection from './components/HeaderSection.vue';
import ButtonsSection from './components/ButtonsSection.vue';
import ControlsSection from './components/ControlsSection.vue';
import CalendarSection from './components/CalendarSection.vue';
import TablesSection from './components/TablesSection.vue';
import FooterSection from './components/FooterSection.vue';
import ContentSection from './components/ContentSection.vue';
import ConfirmationModal from '@/components/basics/modal/ConfirmationModal.vue';
import { editPlusClientLayout as _editPlusClientLayout} from '@/services/PlusClientService';
import { deletePlusClientLayoutLogo as _deletePlusClientLayoutLogo } from '@/services/PlusClientService';

export default {
  name: 'MobileConfiguration',
  components: {
      Panels,
      HeaderSection,
      ButtonsSection,
      ControlsSection,
      CalendarSection,
      TablesSection,
      FooterSection,
      ActionButton,
      ConfirmationModal,
      ContentSection
  },
  props: {
      value: {
        type: Object,
        default: () => {}
      }
  },
  data() {
      return {
          panel: null,
          showCleanModal: false,
          sectionModel: null,
          model: {
              // Header
              headerBackgroundColor: null,
              headerBottomBorderColor: null,
              headerTextColor: null,
              headerTextSize: null,
              plusClientHeaderLogo: null,
              plusClientHeaderImageFile: null,
              // Header


              // Content
              contentPageBackgroundColor: null,
              contentBackgroundColor: null,
              contentTitleFontSize: null,
              contentTitleFontWeight: null,
              contentTitleFontColor: null,
              // Content


              // Buttons
              buttonBackgroundColor: null,
              buttonFontColor: null,
              buttonFontSize: null,
              buttonBorderColor: null,
              buttonBorderSize: null,

              buttonModalBackgroundColor: null,
              buttonModalFontColor: null,
              buttonModalFontSize: null,
              buttonModalBorderColor: null,
              buttonModalBorderSize: null,

              buttonTableBackgroundColor: null,
              buttonTableFontColor: null,
              buttonTableFontSize: null,
              buttonTableBorderColor: null,
              buttonTableBorderSize: null,

              buttonDocumentUploadBackgroundColor: null,
              buttonDocumentUploadFontColor: null,
              buttonDocumentDownloadBackgroundColor: null,
              buttonDocumentDownloadFontColor: null,
              // Buttons


              // Controls
              formControlLabelColor: null,
              formControlIconColor: null,
              formControlFontSize: null,
              formControlFontColor: null,
              formControlFontWeight: null,

              fieldsetHeaderBackgroundColor: null,
              fieldsetHeaderFontColor: null,
              fieldsetHeaderFontSize: null,
              fieldsetHeaderFontWeight: null,
              fieldsetHeaderModalBackgroundColor: null,

              fieldsetHeaderModalFontColor: null,
              fieldsetHeaderModalFontSize: null,
              fieldsetHeaderModalFontWeight: null,
              checkboxColor: null,
              checkboxMarkColor: null,

              hyperLinkColor: null,
              // Controls


              calendarItemBackgroundColor: null,
              calendarItemFontColor: null,
              calendarItemFontSize: null,
              calendarItemBorderColor: null,
              calendarHeaderBackgroundColor: null,
              calendarHeaderFontColor: null,
              calendarSelectedDateBackgroundColor: null,
              calendarSelectedDateFontColor: null,

              tableHeaderBackgroundColor: null,
              tableHeaderFontColor: null,
              tableHeaderFontSize: null,
              tableRowBackgroundColor: null,
              tableRowAlternateBackgroundColor: null,
              tableCompletedIconColor: null,


              footerBackgroundTopColor: null,
              footerBackgroundBottomColor: null,
              footerFontTopColor: null,
              footerFontBottomColor: null
          },
          panelOptions: [
            {
                title: 'headerSection',
                id: 'HEADER_SECTION'
            },
            {
                title: 'contentSection',
                id: 'CONTENT_SECTION'
            },
            {
                title: 'buttonsSection',
                id: 'BUTTONS_SECTION'
            },
            {
                title: 'controlsSection',
                id: 'CONTROLS_SECTION'
            },
            {
                title: 'calendarSection',
                id: 'CALENDAR_SECTION'
            },
            {
                title: 'tablesSection',
                id: 'TABLES_SECTION'
            },
            {
                title: 'footerSection',
                id: 'FOOTER_SECTION'
            }
          ],
          actions: [
            {
              label: this.$t('copyWebStyle'),
              iconClass: 'far fa-clone',
              iconColor: '#24852E',
              action: () => this.$emit('on-clone'),
            },
            {
              label: this.$t('cleanStyle'),
              iconClass: 'far fa-trash-alt',
              iconColor: '#FF0000',
              action: () => this.showCleanModal = true
            },
          ],
      }
  },
  methods: {
    async onDeleteLogo() {
      await _deletePlusClientLayoutLogo({
        plusClientConfigurationId: this.value.plusClientLayoutDesignConfigurationId,
        key: 'PlusClientHeaderLogo'
      }).then(() => {
        this.value.plusClientHeaderImageFile = null;
        this.value.plusClientHeaderLogo = null;
      }).catch((error) => this.ShowErrorToast(error.response.data.message))
    },
    async onClean() {
      const model = this.sectionModel ? {...this.value, ...this.sectionModel } : {...this.value, ...this.model };

      if (!model.plusClientHeaderLogo && !model.plusClientHeaderImageFile) await this.onDeleteLogo();

      _editPlusClientLayout(model).then(() => {
        this.$emit('input', model);
        this.sectionModel = null;
        this.ShowSuccessSaveToast();
      }).catch((error) => this.ShowErrorToast(error.response.data.message))
      .finally(() => this.showCleanModal = false);
    },
    onSectionClean(model) {
      this.showCleanModal = true;
      this.sectionModel = model;
    }
  }
};
</script>
