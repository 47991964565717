<template>
  <div class="row">
    <div class="col-md-3">
      <filter-color
        v-model="model.formControlLabelColor"
        :label="$t('shapeTextColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.formControlIconColor"
        :label="$t('controlIconColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-numeric
        v-model="model.formControlFontSize"
        :max="999"
        :min="1"
        title="controlTextSize"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.formControlFontColor"
        :label="$t('controlLabelColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-select
        v-model="model.formControlFontWeight"
        fieldtext="text"
        fieldvalue="value"
        :label="$t('boldPercentOfTheField')"
        :options="fontWeightOptions"
        translate
      />
    </div>


    <div class="col-md-3">
      <filter-color
        v-model="model.fieldsetHeaderBackgroundColor"
        :label="$t('fieldBackgroundColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.fieldsetHeaderFontColor"
        :label="$t('fieldFontColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-numeric
        v-model="model.fieldsetHeaderFontSize"
        :max="999"
        :min="1"
        title="fieldLetterSize"
      />
    </div>
    <div class="col-md-3">
      <filter-select
        v-model="model.fieldsetHeaderFontWeight"
        fieldtext="text"
        fieldvalue="value"
        :label="$t('boldPercentOfTheField')"
        :options="fontWeightOptions"
        translate
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.fieldsetHeaderModalBackgroundColor"
        :label="$t('modalFieldBackgroundColor')"
      />
    </div>



    <div class="col-md-3">
      <filter-color
        v-model="model.fieldsetHeaderModalFontColor"
        :label="$t('modalFieldFontColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-numeric
        v-model="model.fieldsetHeaderModalFontSize"
        :max="999"
        :min="1"
        title="modalFieldLetterSize"
      />
    </div>
    <div class="col-md-3">
      <filter-select
        v-model="model.fieldsetHeaderModalFontWeight"
        fieldtext="text"
        fieldvalue="value"
        :label="$t('boldPercentOfModalField')"
        :options="fontWeightOptions"
        translate
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.checkboxColor"
        :label="$t('checkboxColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.checkboxMarkColor"
        :label="$t('colorOfTheSelectedCheckbox')"
      />
    </div>



    <div class="col-md-3">
      <filter-color
        v-model="model.hyperLinkColor"
        :label="$t('hypertextColor')"
      />
    </div>

    <div class="col-md-12 text-right pt-4">
      <button
        class="btn btn-danger mr-2"
        @click="onClean()"
      >
        <i class="fas fa-eraser" /> {{ $t('clear') }}
      </button>
      <button
        class="btn btn-outline-primary"
        variant="outline-primary"
        @click="onSubmit()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>
import FontWeightOptions from '@/constants/FontWeightOptions';
import { editPlusClientLayout as _editPlusClientLayout} from '@/services/PlusClientService';

export default {
  name: 'ControlsSection',
  props: {
      value: {
          type: Object,
          default: () => {}
      }
  },
  data() {
      return {
          fontWeightOptions: FontWeightOptions,
          model: {
            formControlLabelColor: null,
            formControlIconColor: null,
            formControlFontSize: null,
            formControlFontColor: null,
            formControlFontWeight: null,

            fieldsetHeaderBackgroundColor: null,
            fieldsetHeaderFontColor: null,
            fieldsetHeaderFontSize: null,
            fieldsetHeaderFontWeight: null,
            fieldsetHeaderModalBackgroundColor: null,

            fieldsetHeaderModalFontColor: null,
            fieldsetHeaderModalFontSize: null,
            fieldsetHeaderModalFontWeight: null,
            checkboxColor: null,
            checkboxMarkColor: null,

            hyperLinkColor: null,
          }
      }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.model = {
          formControlLabelColor: val.formControlLabelColor,
          formControlIconColor: val.formControlIconColor,
          formControlFontSize: val.formControlFontSize,
          formControlFontColor: val.formControlFontColor,
          formControlFontWeight: val.formControlFontWeight,
          fieldsetHeaderBackgroundColor: val.fieldsetHeaderBackgroundColor,
          fieldsetHeaderFontColor: val.fieldsetHeaderFontColor,
          fieldsetHeaderFontSize: val.fieldsetHeaderFontSize,
          fieldsetHeaderFontWeight: val.fieldsetHeaderFontWeight,
          fieldsetHeaderModalBackgroundColor: val.fieldsetHeaderModalBackgroundColor,
          fieldsetHeaderModalFontColor: val.fieldsetHeaderModalFontColor,
          fieldsetHeaderModalFontSize: val.fieldsetHeaderModalFontSize,
          fieldsetHeaderModalFontWeight: val.fieldsetHeaderModalFontWeight,
          checkboxColor: val.checkboxColor,
          checkboxMarkColor: val.checkboxMarkColor,
          hyperLinkColor: val.hyperLinkColor,
        }
      },
    },
  },
  methods: {
    async onSubmit() {
      const model = {...this.value, ...this.model };
      _editPlusClientLayout(model).then(() => {
        this.$emit('input', model);
        this.ShowSuccessSaveToast();
      }).catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onClean() {
      const model = {
        formControlLabelColor: null,
        formControlIconColor: null,
        formControlFontSize: null,
        formControlFontColor: null,
        formControlFontWeight: null,
        fieldsetHeaderBackgroundColor: null,
        fieldsetHeaderFontColor: null,
        fieldsetHeaderFontSize: null,
        fieldsetHeaderFontWeight: null,
        fieldsetHeaderModalBackgroundColor: null,
        fieldsetHeaderModalFontColor: null,
        fieldsetHeaderModalFontSize: null,
        fieldsetHeaderModalFontWeight: null,
        checkboxColor: null,
        checkboxMarkColor: null,
        hyperLinkColor: null,
      };
      this.$emit('on-clean', model);
    }
  }
};
</script>
