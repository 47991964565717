<template>
  <div class="row">
    <div class="col-md-3">
      <filter-color
        v-model="model.contentPageBackgroundColor"
        :label="$t('pageBackgroundColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.contentBackgroundColor"
        :label="$t('contentBackgroundColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-numeric
        v-model="model.contentTitleFontSize"
        :max="999"
        :min="1"
        title="contentTitleFontSize"
      />
    </div>
    <div class="col-md-3">
      <filter-select
        v-model="model.contentTitleFontWeight"
        fieldtext="text"
        fieldvalue="value"
        :label="$t('boldPercentOfTheTitle')"
        :options="fontWeightOptions"
        translate
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.contentTitleFontColor"
        :label="$t('titleFontColor')"
      />
    </div>
    <div class="col-md-3 pt-4">
      <b-form-checkbox
        v-model="model.noShowContentDisclaimer"
        class="mb-1 float-left cus-checkbox-margin-top"
        switch
      >
        {{ $t('noShowContentDisclaimer') }}
      </b-form-checkbox>
    </div>
    <div class="col-md-12 text-right pt-4">
      <button class="btn btn-danger mr-2" @click="onClean()">
        <i class="fas fa-eraser" /> {{ $t('clear') }}
      </button>
      <button
        class="btn btn-outline-primary"
        variant="outline-primary"
        @click="onSubmit()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>
import FontWeightOptions from '@/constants/FontWeightOptions';
import { editPlusClientLayout as _editPlusClientLayout } from '@/services/PlusClientService';

export default {
  name: 'ContentSection',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fontWeightOptions: FontWeightOptions,
      model: {
        contentPageBackgroundColor: null,
        contentBackgroundColor: null,
        contentTitleFontSize: null,
        contentTitleFontWeight: null,
        contentTitleFontColor: null,
        noShowContentDisclaimer: null
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.model = {
          contentPageBackgroundColor: val.contentPageBackgroundColor,
          contentBackgroundColor: val.contentBackgroundColor,
          contentTitleFontSize: val.contentTitleFontSize,
          contentTitleFontWeight: val.contentTitleFontWeight,
          contentTitleFontColor: val.contentTitleFontColor,
          noShowContentDisclaimer: val.noShowContentDisclaimer
        };
      },
    },
  },
  methods: {
    async onSubmit() {
      const model = { ...this.value, ...this.model };
      _editPlusClientLayout(model)
        .then(() => {
          this.$emit('input', model);
          this.ShowSuccessSaveToast();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onClean() {
      const model = {
        contentBackgroundColor: null,
        contentTitleFontSize: null,
        contentTitleFontWeight: null,
        contentTitleFontColor: null,
        noShowContentDisclaimer: null
      };
      this.$emit('on-clean', model);
    },
    onNoShowContentDisclaimerChanged(value) {
      this.model.noShowContentDisclaimer = value;
    },
  },
};
</script>
<style lang="scss">
  .cus-checkbox-margin-top {
    margin-top: 0.7rem !important;
  }
</style>
