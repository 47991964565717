<template>
  <div class="row">
    <div class="col-md-12">
      <action-button :actions="actions" :text="$t('actions')" />
    </div>
    <div class="col-md-12 mt-4">
      <WebConfigurationLegacy
        v-if="showLegacy"
        :value="value"
        @input="$emit('input', $event)"
      />
      <WebConfigurationUpgrade
        v-else
        :value="value"
        @input="$emit('input', $event)"
      />
    </div>
    <div class="col-md-12 mt-3">
      <button class="btn btn-default mr-2" @click="$emit('go-to', 0)">
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </button>
      <button
        class="btn btn-outline-primary float-right"
        variant="outline-primary"
        @click="$emit('go-to', 2)"
      >
        {{ $t('continue') }} <i class="far fa-arrow-alt-circle-right" />
      </button>
    </div>
    <confirmation-modal
      v-model="showCleanModal"
      text="doYouWantToRemoveAllStyles"
      @on-confirm="onClean()"
      @on-reject="showCleanModal = false"
    />
  </div>
</template>

<script>
import ActionButton from '@/components/basics/buttons/ActionButton';
import WebConfigurationLegacy from './components/WebConfigurationLegacy.vue';
import WebConfigurationUpgrade from './components/WebConfigurationUpgrade.vue';
import ConfirmationModal from '@/components/basics/modal/ConfirmationModal.vue';
import { editPlusClientLayout as _editPlusClientLayout } from '@/services/PlusClientService';
import { deletePlusClientLayoutLogo as _deletePlusClientLayoutLogo } from '@/services/PlusClientService';

export default {
  name: 'WebConfiguration',
  components: {
    ActionButton,
    WebConfigurationLegacy,
    WebConfigurationUpgrade,
    ConfirmationModal,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showLegacy: false,
      showCleanModal: false
    };
  },
  computed: {
    actions() {
      return [
        {
          label: this.$t('cleanStyle'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => (this.showCleanModal = true),
        },
        {
          label: this.$t(
            this.showLegacy ? 'removeLegacyMode' : 'changeToLegacyMode',
          ),
          iconClass: 'fas fa-exchange',
          iconColor: 'green',
          action: () => (this.showLegacy = !this.showLegacy),
        },
      ];
    },
  },
  methods: {
    async onClean() {
      const model = this.sectionModel
        ? { ...this.value, ...this.sectionModel }
        : { ...this.value, ...this.model };

      if (!model.plusClientHeaderLogo && !model.plusClientHeaderImageFile)
        await this.onDeleteLogo();

      _editPlusClientLayout(model)
        .then(() => {
          this.$emit('input', model);
          this.sectionModel = null;
          this.ShowSuccessSaveToast();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message))
        .finally(() => (this.showCleanModal = false));
    },
    async onDeleteLogo() {
      await _deletePlusClientLayoutLogo({
        plusClientConfigurationId:
          this.value.plusClientLayoutDesignConfigurationId,
        key: 'PlusClientHeaderLogo',
      })
        .then(() => {
          this.value.plusClientHeaderImageFile = null;
          this.value.plusClientHeaderLogo = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
