<template>
  <div>
    <content-header :title="$t('appointmentplusmaintenance')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filter')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-3"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getPlusClients(1)"
        />
      </div>
      <separator
        class="my-3"
        :text="$t('listOfAppointmentPlus')"
      />
      <div class="row">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="goToCreatePlusClient"
            :text="$t('create')"
          />
        </div>
      </div>
      <div class="row">
        <div
          v-for="(client, index) in plusClients.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="client"
            @on-clone="onClonePlusClient(client)"
            @on-delete="onDeletePlusClient(client)"
            @on-edit="onEditPlusClient(client)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="plusClients.count > 0"
        id="pgPager"
        class="my-3"
        :data="plusClients"
        :page-size="pageSize"
        @pagination-go-page="getPlusClients($event)"
        @pagination-rows-per-page="getPlusClients(pageIndex, $event)"
      />
      <CustomNotFound
        v-if="noResultsFound"
        :text="$t('noAppointmentPlusFoundForFilter')"
      />
    </div>
    <DeleteModal
      v-model="showDeleteModal"
      error-message="plusClientNameIsNotCorrect"
      :item="selectedPlusClient"
      label="plusClientName"
      @on-confirm="onConfirmDelete()"
    />
  </div>
</template>
<script>
import sessionState from '@/mixins/sessionState';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader.vue';
import FilterManager from '@/components/FilterManager';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import Separator from '@/components/Separator';
import SearchResult from './components/SearchResult.vue';
import CustomPagination from '@/components/CustomPagination.vue';
import { getOrganizations } from '@/services/OrganizationService';
import { getCompanies } from '@/services/CompanyService';
import {
  getPlusClients as _getPlusClients,
  deletePlusClient as _deletePlusClient,
} from '@/services/PlusClientService';
import DeleteModal from '@/components/DeleteModal';

export default {
  name: 'AppointmentPlusList',
  components: {
    contentHeader,
    SubHeader,
    FilterManager,
    CustomNotFound,
    Separator,
    SearchResult,
    CustomPagination,
    DeleteModal,
  },
  data() {
    return {
      filters: {
        organization: [],
        company: [],
        name: '',
      },
      selectedFilters: {
        organization: [],
        company: [],
        name: '',
      },
      noResultsFound: false,
      plusClients: [],
      pageSize: 10,
      pageIndex: 1,
      selectedPlusClient: null,
      showDeleteModal: false,
    };
  },
  mixins: [sessionState],
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'organization',
          label: this.$t('organization'),
          component: 'FilterMultiSelect',
          options: this.filters.organization,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.organization,
          onChanged: this.onOrganizationChanged,
        },
        {
          rules: '',
          name: 'company',
          label: this.$t('company'),
          component: 'FilterMultiSelect',
          options: this.filters.company,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.company,
          onChanged: this.onCompanyChanged,
        },
        {
          name: 'name',
          label: this.$t('nameApptPlus'),
          component: 'BaseInput',
          placeholder: '',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
          onChanged: this.onNameChanged,
        },
      ];
    },
  },
  methods: {
    async loadInitialFilters() {
      await getOrganizations()
        .then(async (resp) => {
          this.filters.organization = resp.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onOrganizationChanged(organization) {
      this.filters.company = [];
      this.selectedFilters.company = [];

      if (!organization || organization.length === 0) {
        this.selectedFilters.organization = [];
      } else {
        this.selectedFilters.organization = [organization];
        this.getCompanies(organization);
      }
    },
    async getCompanies(organization) {
      const organizationId = this.filters.organization
        .filter((o) => o.value == organization.value)
        .map((o) => o.value);

      await getCompanies(organizationId)
        .then((resp) => {
          this.filters.company = resp.data;
          if (this.filters.company && this.filters.company.length == 1) {
            this.selectedFilters.company = this.filters.company;
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onCompanyChanged(company) {
      if (!company || company.length === 0) {
        this.selectedFilters.company = [];
      } else {
        this.selectedFilters.company = [company];
      }
    },
    onNameChanged(name) {
      if (!name || name.length === 0) {
        this.selectedFilters.name = '';
      } else {
        this.selectedFilters.name = name;
      }
    },
    async getPlusClients(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;
      const payload = {
        organizationId: this.selectedFilters.organization.length
          ? this.selectedFilters.organization[0].value
          : null,
        companyId: this.selectedFilters.company.length
          ? this.selectedFilters.company[0].value
          : null,
        name: this.selectedFilters.name,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getPlusClients(payload)
        .then(({ data }) => {
          this.plusClients = data;
          this.plusClients.data.length
            ? (this.noResultsFound = false)
            : (this.noResultsFound = true);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    goToCreatePlusClient() {
      // Persist current state of this view
      this.saveSessionData('plusClientData', Object.assign({}, this.$data));

      this.$router.push({ name: 'plusClientCreate' });
    },
    onDeletePlusClient(plusClient) {
      this.showDeleteModal = true;
      this.selectedPlusClient = plusClient;
    },
    onConfirmDelete() {
      _deletePlusClient(this.selectedPlusClient.plusClientId)
        .then(async () => {
          this.showDeleteModal = false;
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          await this.getPlusClients();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onEditPlusClient(client) {
      // Persist current state of this view
      this.saveSessionData('plusClientData', Object.assign({}, this.$data));

      this.$router.push({
        name: 'plusClientEdit',
        params: { id: client.plusClientId },
      });
    },
    async onClonePlusClient(plusClient) {
      // Persist current state of this view
      this.saveSessionData('plusClientData', Object.assign({}, this.$data));

      this.$router.push({
        name: 'plusClientClone',
        params: { id: plusClient.plusClientId },
      });
    },
  },
  async mounted() {
    // load current state of this view
    const previousStateData = this.loadAndRemoveSessionData('plusClientData');

    if (previousStateData != null) {
      Object.assign(this.$data, previousStateData);
    } else {
      this.loadInitialFilters();
    }
  },
};
</script>
