<template>
  <div class="mt-5 container-fluid">
    <step-wizard
      :cascade-activation="true"
      class="mb-5"
      :step="0"
      :tabs="Tabs"
    />
    <div class="row">
      <div class="col-md-12">
        <validation-observer ref="cloneFormRef" novalidate>
          <create
            class="my-3"
            hide-submit-button
            :value="plusClient"
            @continue="onContinue($event)"
          >
            <div class="mt-3 col-12">
              <div
                border-variant="light"
                class="card p-3"
                style="border: white"
              >
                <div class="row">
                  <div class="col-md-6">
                    <b-form-checkbox v-model="plusClient.copyColors" switch>
                      {{ $t('copyColors') }}
                    </b-form-checkbox>
                    <b-form-checkbox v-model="plusClient.copyLogos" switch>
                      {{ $t('copyLogos') }}
                    </b-form-checkbox>
                    <b-form-checkbox v-model="plusClient.copyImages" switch>
                      {{ $t('copyImages') }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <template v-slot:buttons>
              <button class="btn btn-primary float-right" @click="onSubmit()">
                <i class="far fa-save" /> {{ $t('clone') }}
              </button>
            </template>
          </create>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import Create from './Create';
import StepWizard from '@/components/StepWizard';
import {
  getPlusClient as _getPlusClient,
  createPlusClientClone as _createPlusClientClone,
} from '@/services/PlusClientService';

export default {
  name: 'PlusClientClone',
  components: {
    StepWizard,
    Create,
  },
  created() {},
  data() {
    return {
      plusClient: {
        plusClientId: null,
        companyId: null,
        name: null,
        copyColors: true,
        copyLogos: true,
        copyImages: true,
      },
    };
  },
  mounted() {
    this.plusClient.plusClientId = Number(this.$route.params.id);
    this.loadPlusClient(this.plusClient.plusClientId);
  },
  computed: {
    Tabs() {
      return [
        {
          name: this.$t('plusClientCreation'),
          icon: 'far fa-plus-square',
          disabled: false,
        },
      ];
    },
  },
  props: {},
  methods: {
    loadPlusClient(id) {
      _getPlusClient(id)
        .then((response) => {
          const data = response.data;
          this.plusClient = {
            ...this.plusClient,
            organizationId: data.organizationId,
            companyId: data.companyId,
            name: `${data.name} - copy`,
          };
        })
        .catch((error) => {
          if (error.response.status === 404)
            this.$router.push({ name: 'plusClientsList' });
        });
    },
    onContinue(event) {
      if (event === -1) this.$router.push({ name: 'plusClientsList' });
    },
    async onSubmit() {
      if (!(await this.$refs.cloneFormRef.validate())) return;

      _createPlusClientClone(this.plusClient).then((response) => {
        this.ShowToast(
          this.$t('success'),
          this.$t('plusClientClonedSuccessfully'),
          'success',
        );
        this.$router.push({
          name: 'plusClientEdit',
          params: { id: response.data },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
