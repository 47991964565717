<template>
  <div class="row">
    <div class="col-md-3">
      <filter-color
        v-model="model.buttonBackgroundColor"
        :label="$t('buttonBackgroundColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.buttonFontColor"
        :label="$t('buttonFontColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-numeric
        v-model="model.buttonFontSize"
        :max="999"
        measurement-unit="pt"
        :min="1"
        title="buttonFontSize"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.buttonBorderColor"
        :label="$t('buttonBorderColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-numeric
        v-model="model.buttonBorderSize"
        :min="1"
        title="buttonBorderSize"
      />
    </div>


    <div class="col-md-3">
      <filter-color
        v-model="model.buttonModalBackgroundColor"
        :label="$t('modalButtonBackgroundColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.buttonModalFontColor"
        :label="$t('modalButtonFontColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-numeric
        v-model="model.buttonModalFontSize"
        :max="999"
        measurement-unit="pt"
        :min="1"
        title="modalButtonFontSize"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.buttonModalBorderColor"
        :label="$t('modalButtonBorderColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-numeric
        v-model="model.buttonModalBorderSize"
        :max="999"
        measurement-unit="pt"
        :min="1"
        title="modalButtonBorderSize"
      />
    </div>


    <div class="col-md-3">
      <filter-color
        v-model="model.buttonTableBackgroundColor"
        :label="$t('tableButtonBackgroundColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.buttonTableFontColor"
        :label="$t('tableButtonFontColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-numeric
        v-model="model.buttonTableFontSize"
        :max="999"
        measurement-unit="pt"
        :min="1"
        title="tableButtonFontSize"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.buttonTableBorderColor"
        :label="$t('tableButtonBorderColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-numeric
        v-model="model.buttonTableBorderSize"
        :max="999"
        measurement-unit="pt"
        :min="1"
        title="tableButtonBorderSize"
      />
    </div>


    <div class="col-md-3">
      <filter-color
        v-model="model.buttonDocumentUploadBackgroundColor"
        :label="$t('documentUploadBackgroundColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.buttonDocumentUploadFontColor"
        :label="$t('documentUploadFontColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.buttonDocumentDownloadBackgroundColor"
        :label="$t('documentDownloadBackgroundColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.buttonDocumentDownloadFontColor"
        :label="$t('documentDownloadFontColor')"
      />
    </div>


    <div class="col-md-12 text-right pt-4">
      <button
        class="btn btn-danger mr-2"
        @click="onClean()"
      >
        <i class="fas fa-eraser" /> {{ $t('clear') }}
      </button>
      <button
        class="btn btn-outline-primary"
        variant="outline-primary"
        @click="onSubmit()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>
import { editPlusClientLayout as _editPlusClientLayout} from '@/services/PlusClientService';

export default {
  name: 'ButtonsSection',
  props: {
      value: {
          type: Object,
          default: () => {}
      }
  },
  data() {
    return {
      model: {
        buttonBackgroundColor: null,
        buttonFontColor: null,
        buttonFontSize: null,
        buttonBorderColor: null,
        buttonBorderSize: null,

        buttonModalBackgroundColor: null,
        buttonModalFontColor: null,
        buttonModalFontSize: null,
        buttonModalBorderColor: null,
        buttonModalBorderSize: null,

        buttonTableBackgroundColor: null,
        buttonTableFontColor: null,
        buttonTableFontSize: null,
        buttonTableBorderColor: null,
        buttonTableBorderSize: null,

        buttonDocumentUploadBackgroundColor: null,
        buttonDocumentUploadFontColor: null,
        buttonDocumentDownloadBackgroundColor: null,
        buttonDocumentDownloadFontColor: null,
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.model = {
          buttonBackgroundColor: val.buttonBackgroundColor,
          buttonFontColor: val.buttonFontColor,
          buttonFontSize: val.buttonFontSize,
          buttonBorderColor: val.buttonBorderColor,
          buttonBorderSize: val.buttonBorderSize,

          buttonModalBackgroundColor: val.buttonModalBackgroundColor,
          buttonModalFontColor: val.buttonModalFontColor,
          buttonModalFontSize: val.buttonModalFontSize,
          buttonModalBorderColor: val.buttonModalBorderColor,
          buttonModalBorderSize: val.buttonModalBorderSize,

          buttonTableBackgroundColor: val.buttonTableBackgroundColor,
          buttonTableFontColor: val.buttonTableFontColor,
          buttonTableFontSize: val.buttonTableFontSize,
          buttonTableBorderColor: val.buttonTableBorderColor,
          buttonTableBorderSize: val.buttonTableBorderSize,

          buttonDocumentUploadBackgroundColor: val.buttonDocumentUploadBackgroundColor,
          buttonDocumentUploadFontColor: val.buttonDocumentUploadFontColor,
          buttonDocumentDownloadBackgroundColor: val.buttonDocumentDownloadBackgroundColor,
          buttonDocumentDownloadFontColor: val.buttonDocumentDownloadFontColor,
        }
      },
    },
  },
  methods: {
    async onSubmit() {
      const model = {...this.value, ...this.model };
      _editPlusClientLayout(model).then(() => {
        this.$emit('input', model);
        this.ShowSuccessSaveToast();
      }).catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onClean() {
      const model = {
        buttonBackgroundColor: null,
        buttonFontColor: null,
        buttonFontSize: null,
        buttonBorderColor: null,
        buttonBorderSize: null,

        buttonModalBackgroundColor: null,
        buttonModalFontColor: null,
        buttonModalFontSize: null,
        buttonModalBorderColor: null,
        buttonModalBorderSize: null,

        buttonTableBackgroundColor: null,
        buttonTableFontColor: null,
        buttonTableFontSize: null,
        buttonTableBorderColor: null,
        buttonTableBorderSize: null,

        buttonDocumentUploadBackgroundColor: null,
        buttonDocumentUploadFontColor: null,
        buttonDocumentDownloadBackgroundColor: null,
        buttonDocumentDownloadFontColor: null,
      };
      this.$emit('on-clean', model);
    }
  }
};
</script>
