<template>
  <div class="mt-5 container-fluid">
    <step-wizard
      class="mb-3"
      :step="stepIndex"
      :tabs="tabs"
      @OnStepChange="onStepChange($event)"
    />

    <div id="tab-container">
      <div
        id="create"
        class="tab-pane fade"
        :class="{ 'active show': isActive(0) }"
        role="tabpanel"
      >
        <create
          class="my-3"
          :value="plusClient"
          @continue="onContinue($event)"
          @submmited="loadPlusClient(plusClientId)"
        />
      </div>
      <div
        id="web-configuration"
        class="tab-pane fade"
        :class="{ 'active show': isActive(1) }"
        role="tabpanel"
      >
        <WebConfiguration
          class="my-3"
          v-model="webConfigurationModel"
          @go-to="onStepChange($event)"
        />
      </div>
      <div
        id="mobile-configuration"
        class="tab-pane fade"
        :class="{ 'active show': isActive(2) }"
        role="tabpanel"
      >
        <MobileConfiguration
          class="my-3"
          v-model="mobileConfigurationModel"
          @go-to="onStepChange($event)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import StepWizard from '@/components/StepWizard';
import Create from './Create';
import MobileConfiguration from './MobileConfiguration';
import WebConfiguration from './WebConfiguration';
import { getPlusClient as _getPlusClient } from '@/services/PlusClientService';
import PlusClientScreenType from '@/constants/PlusClientScreenType';

export default {
  name: 'PlusClientMaster',
  components: {
    Create,
    StepWizard,
    MobileConfiguration,
    WebConfiguration
  },
  data() {
    return {
      stepIndex: 0,
      plusClient: {
        plusClientId: null,
        name: null,
        organizationId: null,
        companyId: null,
        preloadService: null,
        allowServiceChange: null,
        allowSubService: null,
        allowCitySelection: null,
        displayOnlyWithAvailableSpaces: null,
        allowDocumentUpload: null,
      },
      mobileConfigurationModel: {},
      webConfigurationModel: {}
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.name === 'plusClientEdit') {
          const plusClientId = Number(val.params.id);
          this.loadPlusClient(plusClientId);
          this.plusClient.plusClientId = plusClientId;
        } else {
          this.plusClient.plusClientId = null;
        }
      },
    },
  },
  methods: {
    loadPlusClient(id) {
      _getPlusClient(id)
        .then((response) => {
          this.plusClient = {
            ...this.plusClient,
            ...response.data
          };
          if (this.plusClient && this.plusClient.plusClientLayoutDesignConfigurations?.length) {
            const mobile = this.plusClient.plusClientLayoutDesignConfigurations.find(x => x.plusClientScreenTypeId == PlusClientScreenType.Mobile);
            if (mobile) this.mobileConfigurationModel = mobile;

            const web = this.plusClient.plusClientLayoutDesignConfigurations.find(x => x.plusClientScreenTypeId == PlusClientScreenType.Web);
            if (web) this.webConfigurationModel = web;
          }
        })
        .catch((error) => {
          if (error.response.status === 404)
            this.$router.push({ name: 'plusClientsList' });
        });
    },
    onContinue(event) {
      const isNegative = event < 0;
      const nextPosition = this.stepIndex + event;
      if (nextPosition === -1) this.$router.push({ name: 'plusClientList' });

      if (isNegative) this.stepIndex = nextPosition;
      else {
        if (this.stepIndex === 0) this.stepIndex = nextPosition;
        else if (this.stepIndex === 1) this.stepIndex = nextPosition;
      }
    },
    isActive(step) {
      return this.stepIndex == step;
    },
    onStepChange(step) {
      this.stepIndex = step;
    },
  },
  computed: {
    tabs() {
      return [
        {
          name: this.$t('plusClientCreation'),
          icon: 'far fa-user',
          disabled: false,
        },
        {
          name: this.$t('plusClientWebConfiguration'),
          icon: 'far fa-tv-alt',
          disabled: !this.plusClient.plusClientId,
        },
        {
          name: this.$t('plusClientMobileConfiguration'),
          icon: 'far fa-mobile-android-alt',
          disabled: !this.plusClient.plusClientId,
        }
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .nav,
.nav-pills {
  display: none;
}
.tab-pane {
  display: none;
}
.tab-pane.active.show {
  display: block;
}
</style>
