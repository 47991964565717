<template>
  <div class="row">
    <div class="col-md-4">
      <filter-color
        v-model="model.buttonBackgroundColor"
        :label="$t('buttonBackgroundColor')"
      />
    </div>
    <div class="col-md-4">
      <filter-color
        v-model="model.buttonFontColor"
        :label="$t('buttonFontColor')"
      />
    </div>
    <div class="col-md-4">
      <filter-color
        v-model="model.buttonBorderColor"
        :label="$t('buttonBorderColor')"
      />
    </div>

    <div class="col-md-4">
      <filter-color
        v-model="model.buttonModalBackgroundColor"
        :label="$t('activeButtonBackgroundColor')"
      />
    </div>
    <div class="col-md-4">
      <filter-color
        v-model="model.buttonModalFontColor"
        :label="$t('activeButtonFontColor')"
      />
    </div>
    <div class="col-md-4">
      <filter-color
        v-model="model.buttonModalBorderColor"
        :label="$t('activeButtonBorderColor')"
      />
    </div>

    <div class="col-md-4">
      <filter-color
        v-model="model.buttonTableBackgroundColor"
        :label="$t('documentUploadButtonColor')"
      />
    </div>
    <div class="col-md-4">
      <filter-color
        v-model="model.buttonTableFontColor"
        :label="$t('documentUploadButtonTextColor')"
      />
    </div>

    <div class="col-md-12 text-right pt-4">
      <button class="btn btn-danger mr-2" @click="onClean()">
        <i class="fas fa-eraser" /> {{ $t('clear') }}
      </button>
      <button
        class="btn btn-outline-primary"
        variant="outline-primary"
        @click="onSubmit()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>
import { editPlusClientLayout as _editPlusClientLayout } from '@/services/PlusClientService';

export default {
  name: 'ButtonsSection',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      model: {
        buttonBackgroundColor: null,
        buttonFontColor: null,
        buttonFontSize: null,
        buttonBorderColor: null,
        buttonBorderSize: null,

        buttonModalBackgroundColor: null,
        buttonModalFontColor: null,
        buttonModalFontSize: null,
        buttonModalBorderColor: null,
        buttonModalBorderSize: null,

        buttonTableBackgroundColor: null,
        buttonTableFontColor: null,
        buttonTableFontSize: null,
        buttonTableBorderColor: null,
        buttonTableBorderSize: null,

        buttonDocumentUploadBackgroundColor: null,
        buttonDocumentUploadFontColor: null,
        buttonDocumentDownloadBackgroundColor: null,
        buttonDocumentDownloadFontColor: null,
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.model = {
          buttonBackgroundColor: val.buttonBackgroundColor,
          buttonFontColor: val.buttonFontColor,
          buttonFontSize: val.buttonFontSize,
          buttonBorderColor: val.buttonBorderColor,
          buttonBorderSize: val.buttonBorderSize,

          buttonModalBackgroundColor: val.buttonModalBackgroundColor,
          buttonModalFontColor: val.buttonModalFontColor,
          buttonModalFontSize: val.buttonModalFontSize,
          buttonModalBorderColor: val.buttonModalBorderColor,
          buttonModalBorderSize: val.buttonModalBorderSize,

          buttonTableBackgroundColor: val.buttonTableBackgroundColor,
          buttonTableFontColor: val.buttonTableFontColor,
          buttonTableFontSize: val.buttonTableFontSize,
          buttonTableBorderColor: val.buttonTableBorderColor,
          buttonTableBorderSize: val.buttonTableBorderSize,

          buttonDocumentUploadBackgroundColor:
            val.buttonDocumentUploadBackgroundColor,
          buttonDocumentUploadFontColor: val.buttonDocumentUploadFontColor,
          buttonDocumentDownloadBackgroundColor:
            val.buttonDocumentDownloadBackgroundColor,
          buttonDocumentDownloadFontColor: val.buttonDocumentDownloadFontColor,
        };
      },
    },
  },
  methods: {
    async onSubmit() {
      const model = { ...this.value, ...this.model };
      _editPlusClientLayout(model)
        .then(() => {
          this.$emit('input', model);
          this.ShowSuccessSaveToast();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onClean() {
      const model = {
        buttonBackgroundColor: null,
        buttonFontColor: null,
        buttonFontSize: null,
        buttonBorderColor: null,
        buttonBorderSize: null,

        buttonModalBackgroundColor: null,
        buttonModalFontColor: null,
        buttonModalFontSize: null,
        buttonModalBorderColor: null,
        buttonModalBorderSize: null,

        buttonTableBackgroundColor: null,
        buttonTableFontColor: null,
        buttonTableFontSize: null,
        buttonTableBorderColor: null,
        buttonTableBorderSize: null,

        buttonDocumentUploadBackgroundColor: null,
        buttonDocumentUploadFontColor: null,
        buttonDocumentDownloadBackgroundColor: null,
        buttonDocumentDownloadFontColor: null,
      };
      this.$emit('on-clean', model);
    },
  },
};
</script>
