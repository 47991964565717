const FontWeightOptions = Object.freeze([
    { value: 100, text: '100Lightest' },
    { value: 200, text: '200' },
    { value: 300, text: '300' },
    { value: 400, text: '400Normal' },
    { value: 500, text: '500' },
    { value: 600, text: '600' },
    { value: 700, text: '700Bold' },
    { value: 800, text: '800' },
    { value: 900, text: '900Boldest' },
  ]);
  
  export default FontWeightOptions;
  