<template>
  <b-form-group :label="label + ':'">
    <b-input-group>
      <b-form-file
        v-if="!image && !imageFile"
        ref="fileInput"
        :accept="accept"
        size="md"
        @input="onSelectedImage($event)"
      />
      <b-form-input v-else readonly :value="imageName" />
      <template #append>
        <b-button
          v-if="image || imageFile"
          variant="outline-success"
          @click="onShowImage()"
        >
          <i class="far fa-eye" />
        </b-button>
        <b-button
          v-if="image || imageFile"
          variant="outline-danger"
          @click="cleanFiles()"
        >
          <i class="fas fa-trash" />
        </b-button>
      </template>
    </b-input-group>
    <error
      v-if="error"
      class="mt-n1"
      :message="errorMsg"
      variant="no-border no-background"
    />
    <custom-modal id="image-size-modal" ref="image-size-ref" size="md">
      <p class="text-center">
        {{ $t('maxImageSize') }} {{ maxWidth }} x {{ maxHeight }}
      </p>
      <div class="d-flex justify-content-center">
        <base-filled-button
          bg-color="#FF8105"
          :text="$t('confirm')"
          :on-click="
            () => {
              this.$refs['image-size-ref'].hide();
            }
          "
        />
      </div>
    </custom-modal>
  </b-form-group>
</template>

<script>
import Error from '@/components/basics/error/CustomError.vue';
import Swal from 'sweetalert2';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import BaseFilledButton from '@/components/basics/buttons/BaseFilledButton';

export default {
  name: 'CustomImageInput',
  components: {
    Error,
    CustomModal,
    BaseFilledButton,
  },
  props: {
    label: {
      type: String,
      default: 'notavailable', // Taken from the locale dictionary
      required: true,
      meta: {
        description: 'Label text',
        example: 'Office',
      },
    },
    value: {
      type: [File, Object],
      default: null,
    },
    accept: {
      type: String,
      default: '.jpg,.jpeg,.png,.svg',
    },
    maxSize: {
      //Size in MB
      type: Number,
      default: 2,
    },
    maxWidth: {
      //Size in PX
      type: Number,
      default: 9999,
    },
    maxHeight: {
      //Size in PX
      type: Number,
      default: 9999,
    },
    error: {
      type: String,
      default: '',
      meta: {
        description: 'Error state',
      },
    },
    errorMsg: {
      type: String,
      default: () => '',
      meta: {
        description: 'Error Message',
        example: 'This field is required',
      },
    },
    file: {
      type: Object,
      default: () => null,
    },
    autoDelete: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      image: null,
      imageFile: null,
    };
  },
  computed: {
    imageName() {
      if (this.image) return this.image.name;
      if (this.file) return this.file.fileName;
      return '';
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.image = val;
      },
    },
    file: {
      immediate: true,
      handler(val) {
        this.imageFile = val;
      },
    },
  },
  methods: {
    validateFile(file) {
      //check extension and size
      const filename = file.name;
      const fileExtension = filename
        .split('.')[filename.split('.').length - 1].toLowerCase();
      
      const acceptFileExtensions = this.accept
        .split('.')
        .map((s) => s.replace(',', '').toLowerCase())
        .filter((s) => s);
      let validationResult = true;

      //validate extension
      if (!acceptFileExtensions.includes(fileExtension)) {
        this.ShowToast(
          'Error',
          `Favor de seleccionar un archivo de tipo ${acceptFileExtensions}.`,
          'error',
        );
        validationResult = false;
      }

      return validationResult;
    },
    cleanFiles(force = false) {
      if (this.$refs.fileInput) this.$refs.fileInput.reset();

      if (this.autoDelete || force) {
        this.image = null;
        this.imageFile = null;
      }

      this.$emit('clearFile', this.imageFile);
    },
    onSelectedImage(mediaFile) {
      //validate file size in mb
      const fileSize = (mediaFile.size / 1048576).toFixed(2);
      
      if (fileSize > this.maxSize) {
        this.ShowToast(
          'Error',
          `Favor de seleccionar el archivo de tamaño menor o igual a ${this.maxSize}MB.`,
          'error',
        );

        this.cleanFiles(true);
        return;
      }

      if (mediaFile == null) return;
      var fr = new FileReader();
      fr.readAsDataURL(mediaFile);
      fr.onload = () => {
        if (!mediaFile.type.startsWith('image/')) {
          this.image = mediaFile;
          this.$emit('input', this.image);
          return;
        }
        var img = new Image();
        img.onload = () => {
          if (img.width > this.maxWidth || img.height > this.maxHeight) {
            this.$refs['image-size-ref'].show();
            this.$refs.fileInput.reset();
            return;
          } else {
            if (mediaFile && !this.validateFile(mediaFile))
              return this.cleanFiles();

            this.image = mediaFile;
            this.$emit('input', this.image);
          }
        };
        img.src = fr.result;
      };
    },
    showImage(imageUrl, isImage) {
      const html = document.createElement('div');

      if (isImage) {
        // Create video element
        const img = document.createElement('img');
        if (!this.imageFile || !this.imageFile.content) {
          // Use remote file
          img.src = imageUrl;

          html.appendChild(img);
        } else {
          const svgBytes = Uint8Array.from(atob(this.imageFile.content), c => c.charCodeAt(0));
          const svgBlob = new Blob([svgBytes], { type: 'image/svg+xml' });
          const reader = new FileReader();
          reader.onload = function () {
            // Create an image element
            const img = document.createElement('img');

            // Set the data URL as the source of the image
            img.src = reader.result;

            // Append the image to the document
            html.appendChild(img);
          };

          reader.readAsDataURL(svgBlob);
        }
      } else {
        // Create video element
        const video = document.createElement('video');

        // Use remote file
        video.src = imageUrl;
        video.controls = true;
        video.muted = false;
        video.autoplay = true;
        video.width = 320; // in px

        html.appendChild(video);
      }

      Swal.fire({
        title: this.label,
        text: this.imageName,
        html,
        imageAlt: this.imageName,
      }).then(html?.remove);
    },
    onShowImage() {
      if (this.image) {
        var reader = new FileReader();
        reader.onload = () =>
          this.showImage(
            reader.result,
            !reader.result.startsWith('data:video/'),
          );
        reader.readAsDataURL(this.image);
      } else if (this.imageFile)
        this.showImage(
          this.imageFile.publicUrl,
          !this.imageFile.publicUrl.includes('.mp4'),
        );
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .form-control:disabled,
::v-deep .form-control[readonly] {
  background-color: $color-white;
}
</style>
