<template>
  <div class="row">
    <div class="col-md-12">
      <div
        class="header"
        :class="{ 'mt-3': isOpen }"
        :style="{
          'border-top-left-radius': isOpen ? '10px' : 0,
          'border-top-right-radius': isOpen ? '10px' : 0,
        }"
        @click="onSelect()"
      >
        {{ $t(title) }}
        <i
          class="float-right mt-2 mr-2 fas"
          :class="{ 'fa-chevron-down': !isOpen, 'fa-chevron-up': isOpen }"
        />
      </div>
    </div>
    <div class="col-md-12" :class="{ 'mb-2': isOpen }">
      <transition name="slide">
        <div v-if="isOpen" class="content">
          <div class="row">
            <div class="col-md-12 px-4 py-3">
              <slot />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    value(val) {
      this.isOpen = val;
    },
  },
  methods: {
    onSelect() {
      this.isOpen = !this.isOpen;
      this.$emit('input', this.isOpen);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #c8c8c8;
  cursor: pointer;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #ff8105;
  background-color: #fff9f3;
  border: 1px solid #ff8105;
}
.content {
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 500px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
