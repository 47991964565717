<template>
  <div class="row">
    <div
      v-for="(option, i) in options"
      :key="i"
      class="col-md-12"
    >
      <Accordion
        :title="option.title"
      >
        <slot :name="option.id" />
      </Accordion>
    </div>
  </div>
</template>

<script>
import Accordion from './Accordion.vue';

export default {
  name: 'Panels',
  components: {
      Accordion
  },
  props: {
      value: {
          type: String,
          default: () => ''
      },
      options: {
        type: Array,
        default: () => [],
      }
  }
};
</script>
