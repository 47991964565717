<template>
  <div class="p-3 listview card border-light">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-4 col-lg-3">
        <p>
          <b>{{ $t('appointmentPlusName') }}</b>
          <br />
          {{ value.name }}
        </p>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-3">
        <p>
          <b>{{ $t('organization') }}</b>
          <br />
          {{ value.organizationName }}
        </p>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4">
        <p>
          <b>{{ $t('company') }}</b>
          <br />
          {{ value.companyName }}
        </p>
      </div>
      <div
        class="
          col-12 col-sm-12 col-md-12 col-lg-2
          d-flex
          justify-content-center
        "
      >
        <action-button :actions="actions" :text="$t('actions')" />
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from '@/components/basics/buttons/ActionButton';
export default {
  name: 'UserSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.$emit('on-edit'),
        },
        {
          label: this.$t('clone'),
          iconClass: 'far fa-clone',
          iconColor: '#24852E',
          action: () => this.$emit('on-clone'),
        },
        {
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => this.$emit('on-delete'),
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.listview .cell {
  clear: left;
}
.listview .cell label {
  float: left;
  font-weight: bold;
}
.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
.card {
  word-break: break-word;
}
</style>