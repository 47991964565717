<template>
  <div class="row">
    <div class="col-md-3">
      <filter-color
        v-model="model.calendarItemBackgroundColor"
        :label="$t('backgroundColorElement')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.calendarItemFontColor"
        :label="$t('elementTextColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-numeric
        v-model="model.calendarItemFontSize"
        :clearable="true"
        :max="999"
        measurement-unit="pt"
        :min="1"
        title="elementTextSize"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.calendarItemBorderColor"
        :label="$t('elementBorderColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.calendarHeaderBackgroundColor"
        :label="$t('headerBackgroundColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.calendarHeaderFontColor"
        :label="$t('headerTextColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.calendarSelectedDateBackgroundColor"
        :label="$t('selectedDateBackgroundColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.calendarSelectedDateFontColor"
        :label="$t('textBackgroundColorSelectedDate')"
      />
    </div>
    <div class="col-md-12 text-right pt-4">
      <button
        class="btn btn-danger mr-2"
        @click="onClean()"
      >
        <i class="fas fa-eraser" /> {{ $t('clear') }}
      </button>
      <button
        class="btn btn-outline-primary"
        variant="outline-primary"
        @click="onSubmit()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>
import { editPlusClientLayout as _editPlusClientLayout} from '@/services/PlusClientService';

export default {
  name: 'CalendarSection',
  props: {
      value: {
          type: Object,
          default: () => {}
      }
  },
  data() {
      return {
          model: {
            calendarItemBackgroundColor: null,
            calendarItemFontColor: null,
            calendarItemFontSize: null,
            calendarItemBorderColor: null,
            calendarHeaderBackgroundColor: null,
            calendarHeaderFontColor: null,
            calendarSelectedDateBackgroundColor: null,
            calendarSelectedDateFontColor: null,
          }
      }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.model = {
          calendarItemBackgroundColor: val.calendarItemBackgroundColor,
          calendarItemFontColor: val.calendarItemFontColor,
          calendarItemFontSize: val.calendarItemFontSize,
          calendarItemBorderColor: val.calendarItemBorderColor,
          calendarHeaderBackgroundColor: val.calendarHeaderBackgroundColor,
          calendarHeaderFontColor: val.calendarHeaderFontColor,
          calendarSelectedDateBackgroundColor: val.calendarSelectedDateBackgroundColor,
          calendarSelectedDateFontColor: val.calendarSelectedDateFontColor,
        }
      },
    },
  },
  methods: {
    async onSubmit() {
      const model = {...this.value, ...this.model };
      _editPlusClientLayout(model).then(() => {
        this.$emit('input', model);
        this.ShowSuccessSaveToast();
      }).catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onClean() {
      const model = {
        calendarItemBackgroundColor: null,
        calendarItemFontColor: null,
        calendarItemFontSize: null,
        calendarItemBorderColor: null,
        calendarHeaderBackgroundColor: null,
        calendarHeaderFontColor: null,
        calendarSelectedDateBackgroundColor: null,
        calendarSelectedDateFontColor: null,
      };
      this.$emit('on-clean', model);
    }
  }
};
</script>
