import { apiTurnos } from '@/core/api/';
import {
    serialize
  } from 'object-to-formdata'

export const getPlusClient = id => apiTurnos.get(`/api/plusclient/${id}`);

export const getPlusClients = (payload) => apiTurnos.get('/api/plusclient/search', { params: { ...payload } });

export const deletePlusClient = (plusClientId) => apiTurnos.delete(`/api/plusclient/${plusClientId}`);

export const submitPlusClient = model =>
    apiTurnos[model.displayId ? 'put' : 'post']('/api/plusclient', model);

export const saveParameters = (payload) => apiTurnos.post('/api/plusclient/parameters', payload);

export const createPlusClientClone = (payload) => apiTurnos.post(`/api/plusclient/${payload.plusClientId}/clone`, payload);

export const editPlusClientLayout = model => {
    const payload = serialize(model, {
        indices: true,
        nullsAsUndefineds: true
      });
    return apiTurnos.put('/api/plusclient/layout', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
}

export const deletePlusClientLayoutLogo = (payload) => 
    apiTurnos.delete(`/api/plusclient/${payload.plusClientConfigurationId}/image/delete`, { data: { imageKey: payload.key }});
    