<template>
  <div class="row">
    <div class="col-md-3">
      <filter-color
        v-model="model.headerBackgroundColor"
        :label="$t('headerBackgroundColor')"
      />
    </div>
    <div class="col-md-3">
      <custom-image-input
        v-model="model.headerBackgroundImage"
        :auto-delete="false"
        :file="model.headerBackgroundImageFile"
        :label="$t('headerBackgroundImage')"
        @clearFile="onDeleteBackgroundImage()"
      />
    </div>
    <div class="col-md-3">
      <base-input
        v-model="value.headerBackgroundText"
        :label="$t('headerBackgroundText')"
        :max-length="28"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.headerTextColor"
        :label="$t('headerTextColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-numeric
        v-model="model.headerTextSize"
        :clearable="true"
        :max="999"
        measurement-unit="pt"
        :min="1"
        title="headerTextSize"
      />
    </div>
    <div class="col-md-3">
      <custom-image-input
        v-model="model.plusClientHeaderLogo"
        :auto-delete="false"
        :file="model.plusClientHeaderImageFile"
        :label="$t('logo')"
        @clearFile="onDeleteLogo()"
      />
    </div>

    <div class="col-md-3 pt-4">
      <b-form-checkbox
        v-model="model.noShowHeaderLogo"
        class="mb-1 float-left cus-checkbox-margin-top"
        switch
      >
        {{ $t('noShowHeaderLogo') }}
      </b-form-checkbox>
    </div>
    <div class="col-md-12 text-right pt-4">
      <button class="btn btn-danger mr-2" @click="onClean()">
        <i class="fas fa-eraser" /> {{ $t('clear') }}
      </button>
      <button
        class="btn btn-outline-primary"
        variant="outline-primary"
        @click="onSubmit()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>
import CustomImageInput from '@/components/CustomImageInput';
import { editPlusClientLayout as _editPlusClientLayout } from '@/services/PlusClientService';
import { deletePlusClientLayoutLogo as _deletePlusClientLayoutLogo } from '@/services/PlusClientService';
import BaseInput from '@/components/BaseInput.vue';

export default {
  name: 'HeaderSection',
  components: {
    CustomImageInput,
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: {
        headerBackgroundColor: null,
        headerBottomBorderColor: null,
        headerTextColor: null,
        headerTextSize: null,
        plusClientHeaderLogo: null,
        plusClientHeaderImageFile: null,
        headerBackgroundImage: null,
        headerBackgroundImageFile: null,
        noShowHeaderLogo: null,
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.model = {
          headerBackgroundColor: val.headerBackgroundColor,
          headerBottomBorderColor: val.headerBottomBorderColor,
          headerTextColor: val.headerTextColor,
          headerTextSize: val.headerTextSize,
          plusClientHeaderLogo: val.plusClientHeaderLogo,
          plusClientHeaderImageFile: val.plusClientHeaderImageFile,
          headerBackgroundImage: val.headerBackgroundImage,
          headerBackgroundImageFile: val.headerBackgroundImageFile,
          noShowHeaderLogo: val.noShowHeaderLogo,
        };
      },
    },
  },
  methods: {
    async onSubmit() {
      const model = { ...this.value, ...this.model };
      _editPlusClientLayout(model)
        .then(() => {
          this.$emit('input', model);
          this.ShowSuccessSaveToast();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onDeleteLogo() {
      _deletePlusClientLayoutLogo({
        plusClientConfigurationId:
          this.value.plusClientLayoutDesignConfigurationId,
        key: 'PlusClientHeaderLogo',
      })
        .then(() => {
          this.model.plusClientHeaderImageFile = null;
          this.value.plusClientHeaderImageFile = null;
          this.model.plusClientHeaderLogo = null;
          this.value.plusClientHeaderLogo = null;
          this.ShowSuccessSaveToast();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onDeleteBackgroundImage() {
      _deletePlusClientLayoutLogo({
        plusClientConfigurationId:
          this.value.plusClientLayoutDesignConfigurationId,
        key: 'headerBackgroundImage',
      })
        .then(() => {
          this.model.headerBackgroundImageFile = null;
          this.value.headerBackgroundImageFile = null;
          this.model.headerBackgroundImage = null;
          this.value.headerBackgroundImage = null;
          this.ShowSuccessSaveToast();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onClean() {
      const model = {
        headerBackgroundColor: null,
        headerBottomBorderColor: null,
        headerTextColor: null,
        headerTextSize: null,
        plusClientHeaderLogo: null,
        plusClientHeaderImageFile: null,
        plusClientHeaderBackgroundImage: null,
        plusClientHeaderBackgroundImageFile: null,
        noShowHeaderLogo: null,
      };
      this.$emit('on-clean', model);
    },
    onNoShowHeaderLogoChanged(value) {
      this.model.noShowHeaderLogo = value;
    },
  },
};
</script>
<style lang="scss">
.cus-checkbox-margin-top {
  margin-top: 0.7rem !important;
}
</style>
