<template>
  <div class="row">
    <div class="col-md-12 mt-4">
      <Panels :options="panelOptions">
        <template v-slot:HEADER_SECTION>
          <HeaderSection
            :value="value"
            @input="$emit('input', $event)"
            @on-clean="onSectionClean($event)"
          />
        </template>
        <template v-slot:STEPPER_SECTION>
          <StepperSection
            :value="value"
            @input="$emit('input', $event)"
            @on-clean="onSectionClean($event)"
          />
        </template>
        <template v-slot:CONTENT_SECTION>
          <ContentSection
            :value="value"
            @input="$emit('input', $event)"
            @on-clean="onSectionClean($event)"
          />
        </template>
        <template v-slot:BUTTONS_SECTION>
          <ButtonsSection
            :value="value"
            @input="$emit('input', $event)"
            @on-clean="onSectionClean($event)"
          />
        </template>
        <template v-slot:CONTROLS_SECTION>
          <ControlsSection
            :value="value"
            @input="$emit('input', $event)"
            @on-clean="onSectionClean($event)"
          />
        </template>
        <template v-slot:CALENDAR_SECTION>
          <CalendarSection
            :value="value"
            @input="$emit('input', $event)"
            @on-clean="onSectionClean($event)"
          />
        </template>
        <template v-slot:FOOTER_SECTION>
          <FooterSection
            :value="value"
            @input="$emit('input', $event)"
            @on-clean="onSectionClean($event)"
          />
        </template>
      </Panels>
      <confirmation-modal
        v-model="showCleanModal"
        text="doYouWantToRemoveAllStyles"
        @on-confirm="onClean()"
        @on-reject="showCleanModal = false"
      />
    </div>
  </div>
</template>

<script>
import Panels from '@/components/Panels.vue';
import HeaderSection from './upgrade/HeaderSection.vue';
import StepperSection from './upgrade/StepperSection.vue';
import ButtonsSection from './upgrade/ButtonsSection.vue';
import FooterSection from './upgrade/FooterSection.vue';
import ContentSection from './upgrade/ContentSection.vue';
import ControlsSection from './upgrade/ControlsSection.vue';
import CalendarSection from './upgrade/CalendarSection.vue';
import ConfirmationModal from '@/components/basics/modal/ConfirmationModal.vue';
import { deletePlusClientLayoutLogo as _deletePlusClientLayoutLogo } from '@/services/PlusClientService';
import { editPlusClientLayout as _editPlusClientLayout} from '@/services/PlusClientService';

export default {
  name: 'WebConfiguration',
  components: {
    Panels,
    HeaderSection,
    FooterSection,
    ConfirmationModal,
    ContentSection,
    StepperSection,
    ButtonsSection,
    ControlsSection,
    CalendarSection
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      panel: null,
      showCleanModal: false,
      sectionModel: null,
      model: {
        // Header
        headerBackgroundColor: null,
        headerBottomBorderColor: null,
        headerTextColor: null,
        headerTextSize: null,
        plusClientHeaderLogo: null,
        plusClientHeaderImageFile: null,
        noShowHeaderLogo: null,
        // Header

        // Content
        contentPageBackgroundColor: null,
        contentBackgroundColor: null,
        contentTitleFontSize: null,
        contentTitleFontWeight: null,
        contentTitleFontColor: null,
        noShowContentDisclaimer: null,
        // Content

        // Buttons
        buttonBackgroundColor: null,
        buttonFontColor: null,
        buttonFontSize: null,
        buttonBorderColor: null,
        buttonBorderSize: null,

        buttonModalBackgroundColor: null,
        buttonModalFontColor: null,
        buttonModalFontSize: null,
        buttonModalBorderColor: null,
        buttonModalBorderSize: null,

        buttonTableBackgroundColor: null,
        buttonTableFontColor: null,
        buttonTableFontSize: null,
        buttonTableBorderColor: null,
        buttonTableBorderSize: null,

        buttonDocumentUploadBackgroundColor: null,
        buttonDocumentUploadFontColor: null,
        buttonDocumentDownloadBackgroundColor: null,
        buttonDocumentDownloadFontColor: null,
        // Buttons

        // Controls
        formControlLabelColor: null,
        formControlIconColor: null,
        formControlFontSize: null,
        formControlFontColor: null,
        formControlFontWeight: null,

        fieldsetHeaderBackgroundColor: null,
        fieldsetHeaderFontColor: null,
        fieldsetHeaderFontSize: null,
        fieldsetHeaderFontWeight: null,
        fieldsetHeaderModalBackgroundColor: null,

        fieldsetHeaderModalFontColor: null,
        fieldsetHeaderModalFontSize: null,
        fieldsetHeaderModalFontWeight: null,
        checkboxColor: null,
        checkboxMarkColor: null,

        hyperLinkColor: null,
        // Controls

        calendarItemBackgroundColor: null,
        calendarItemFontColor: null,
        calendarItemFontSize: null,
        calendarItemBorderColor: null,
        calendarHeaderBackgroundColor: null,
        calendarHeaderFontColor: null,
        calendarSelectedDateBackgroundColor: null,
        calendarSelectedDateFontColor: null,

        tableHeaderBackgroundColor: null,
        tableHeaderFontColor: null,
        tableHeaderFontSize: null,
        tableRowBackgroundColor: null,
        tableRowAlternateBackgroundColor: null,
        tableCompletedIconColor: null,

        footerBackgroundTopColor: null,
        footerBackgroundBottomColor: null,
        footerFontTopColor: null,
        footerFontBottomColor: null,
      },
      panelOptions: [
        {
          title: 'headerSection',
          id: 'HEADER_SECTION',
        },
        {
          title: 'stepperSection',
          id: 'STEPPER_SECTION',
        },
        {
          title: 'contentSection',
          id: 'CONTENT_SECTION',
        },
        {
          title: 'buttonsSection',
          id: 'BUTTONS_SECTION',
        },
        {
          title: 'controlsSection',
          id: 'CONTROLS_SECTION',
        },
        {
          title: 'calendarSection',
          id: 'CALENDAR_SECTION',
        },
        {
          title: 'footerSection',
          id: 'FOOTER_SECTION',
        },
      ],
    };
  },
  methods: {
    async onDeleteLogo() {
      await _deletePlusClientLayoutLogo({
        plusClientConfigurationId:
          this.value.plusClientLayoutDesignConfigurationId,
        key: 'PlusClientHeaderLogo',
      })
        .then(() => {
          this.value.plusClientHeaderImageFile = null;
          this.value.plusClientHeaderLogo = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onSectionClean(model) {
      this.showCleanModal = true;
      this.sectionModel = model;
    },
    async onClean() {
      const model = this.sectionModel
        ? { ...this.value, ...this.sectionModel }
        : { ...this.value, ...this.model };

      if (!model.plusClientHeaderLogo && !model.plusClientHeaderImageFile)
        await this.onDeleteLogo();

      _editPlusClientLayout(model)
        .then(() => {
          this.$emit('input', model);
          this.sectionModel = null;
          this.ShowSuccessSaveToast();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message))
        .finally(() => (this.showCleanModal = false));
    },
  },
};
</script>
