<template>
  <div class="row">
    <div class="col-md-3">
      <filter-color
        v-model="model.tableHeaderBackgroundColor"
        :label="$t('headerBackgroundColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.tableHeaderFontColor"
        :label="$t('headerTextColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-numeric
        v-model="model.tableHeaderFontSize"
        :clearable="true"
        :max="999"
        measurement-unit="pt"
        :min="1"
        title="headerTextSize"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.tableRowBackgroundColor"
        :label="$t('rowColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.tableRowAlternateBackgroundColor"
        :label="$t('alternateRowColor')"
      />
    </div>
    <div class="col-md-3">
      <filter-color
        v-model="model.tableCompletedIconColor"
        :label="$t('completedIconColor')"
      />
    </div>
    <div class="col-md-6 text-right pt-4">
      <button
        class="btn btn-danger mr-2"
        @click="onClean()"
      >
        <i class="fas fa-eraser" /> {{ $t('clear') }}
      </button>
      <button
        class="btn btn-outline-primary"
        variant="outline-primary"
        @click="onSubmit()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>
import { editPlusClientLayout as _editPlusClientLayout} from '@/services/PlusClientService';

export default {
  name: 'TablesSection',
  props: {
      value: {
          type: Object,
          default: () => {}
      }
  },
  data() {
      return {
          model: {
            tableHeaderBackgroundColor: null,
            tableHeaderFontColor: null,
            tableHeaderFontSize: null,
            tableRowBackgroundColor: null,
            tableRowAlternateBackgroundColor: null,
            tableCompletedIconColor: null
          }
      }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.model = {
          tableHeaderBackgroundColor: val.tableHeaderBackgroundColor,
          tableHeaderFontColor: val.tableHeaderFontColor,
          tableHeaderFontSize: val.tableHeaderFontSize,
          tableRowBackgroundColor: val.tableRowBackgroundColor,
          tableRowAlternateBackgroundColor: val.tableRowAlternateBackgroundColor,
          tableCompletedIconColor: val.tableCompletedIconColor
        };
      },
    },
  },
  methods: {
    async onSubmit() {
      const model = {...this.value, ...this.model };
      _editPlusClientLayout(model).then(() => {
        this.$emit('input', model);
        this.ShowSuccessSaveToast();
      }).catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onClean() {
      const model = {
        tableHeaderBackgroundColor: null,
        tableHeaderFontColor: null,
        tableHeaderFontSize: null,
        tableRowBackgroundColor: null,
        tableRowAlternateBackgroundColor: null,
        tableCompletedIconColor: null
      };
      this.$emit('on-clean', model);
    }
  }
};
</script>
